import * as yup from 'yup';
import { FC, memo, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation } from 'react-query';
import { InputField } from '@studenten/ui-components';

import { authApi } from 'api_entities/auth';
import { AuthError } from 'api_entities/auth/types';

import { InputWrapper, CaptionText, SubmitButton } from './LoginForm.styles';

const schema = yup
  .object({
    email: yup.string().email().required(),
    password: yup.string().required(),
  })
  .required();

export interface ILoginForm {
  email: string;
  password: string;
}

export type LoginFormProps = {
  onSubmit: () => void;
  loading?: boolean;
};

const LoginForm: FC<LoginFormProps> = ({ onSubmit, loading }) => {
  const { handleSubmit, formState, setError, register } = useForm<ILoginForm>({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    defaultValues: { email: '', password: '' },
    resolver: yupResolver(schema),
  });

  const { mutate: submitForm } = useMutation(authApi.login, {
    onError: (err: AuthError) => {
      if (err.status === 401 || err.status === 400) {
        setError('email', { message: err?.data?.message });
      }
    },
    onSuccess: () => {
      onSubmit();
    },
    retry: false,
  });

  const submitHandler = useCallback(
    (values: ILoginForm) => {
      submitForm(values);
    },
    [submitForm]
  );

  const { errors, isSubmitting } = formState;

  const disableSubmit = loading || isSubmitting;

  return (
    <div>
      <CaptionText>of log in met jouw email</CaptionText>
      <form onSubmit={handleSubmit(submitHandler)}>
        <InputWrapper>
          <InputField
            placeholder="Your email"
            {...register('email')}
            error={errors.email?.message}
            withErrorMessage={!!errors.email}
            touched={!!errors.email}
          />
        </InputWrapper>
        <InputWrapper>
          <InputField
            type="password"
            placeholder="Password"
            {...register('password')}
            error={errors.password?.message}
            withErrorMessage={!!errors.password}
            touched={!!errors.password}
          />
        </InputWrapper>
        <SubmitButton type="submit" disabled={disableSubmit}>
          Login
        </SubmitButton>
      </form>
    </div>
  );
};

export default memo(LoginForm);
