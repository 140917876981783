import { FC, memo } from 'react';
import Head from 'next/head';
import Image from 'next/image';
import Link from 'next/link';
import { useQueryClient } from 'react-query';
import { useRouter } from 'next/router';
import { Icons } from '@studenten/ui-components';

import Logo from '../../../public/assets/logo.svg';

import { useUpToLaptop } from 'hooks/useMediaQuery';
import { APP_ROUTES } from 'constants/routes';
import { CACHE_KEYS } from 'constants/cache';
import { UserProfile } from 'api_entities/user/types';
import { useNonAuthGuard } from 'features/auth/hooks/useNonAuthGuard';
import { useAuthViaSocialNetwork } from 'features/auth/hooks/useAuthViaSocialNetwork';
import { Spinner } from '../../components/Spinner';

import { LoginForm } from './components/LoginForm';

import {
  PageLayout,
  StyledContentWrapper,
  Content,
  SideImage,
  LogoRow,
  LogoLink,
  Title,
  SocialButtonsRow,
  SocialButton,
  Text,
  Form,
  SocialErrorText,
} from './Login.styles';

const Login: FC = () => {
  const { isUserAuth } = useNonAuthGuard();
  const {
    loginViaGoogle,
    loginViaFacebook,
    error: socialLoginErrorMessage,
  } = useAuthViaSocialNetwork();

  const router = useRouter();
  const queryClient = useQueryClient();
  const isMobile = useUpToLaptop();

  const onSubmit = async () => {
    await queryClient.refetchQueries([CACHE_KEYS.USER]);

    const profile = queryClient.getQueryData<UserProfile>(CACHE_KEYS.USER);
    const from = typeof router?.query?.from === 'string' ? router?.query?.from : undefined;

    if (profile) {
      if (!profile.loginViaSocialNetwork && !profile.emailVerifiedAt) {
        await router.replace({
          pathname: APP_ROUTES.VERIFY_EMAIL,
          query: { email: profile.email, ...(from && { from }) },
        });

        return;
      } else if (!profile.onboardAt) {
        await router.replace({
          pathname: APP_ROUTES.ONBOARDING,
          query: { ...(from && { from }) },
        });

        return;
      }
    }

    await router.replace(from || APP_ROUTES.HOME);
  };

  if (isUserAuth) {
    return <Spinner fullScreen />;
  }

  return (
    <>
      <Head>
        <meta key="robots" name="robots" content="noindex" />
        <meta key="googlebot" name="googlebot" content="noindex" />
      </Head>
      <PageLayout>
        <Content>
          <StyledContentWrapper as="main">
            <LogoRow>
              <Link passHref href={APP_ROUTES.HOME}>
                <LogoLink>
                  {isMobile ? (
                    <Image
                      priority
                      src={Logo}
                      layout="fixed"
                      width={109}
                      height={28}
                      alt="Studenten logo."
                    />
                  ) : (
                    <Image
                      priority
                      src={Logo}
                      layout="fixed"
                      width={140}
                      height={36}
                      alt="Studenten logo."
                    />
                  )}
                </LogoLink>
              </Link>
            </LogoRow>
            <Form>
              <Title>
                Hi! <br />
                Meld je aan met jouw account
              </Title>

              <SocialButtonsRow>
                <SocialButton title="login with Google account." onClick={loginViaGoogle}>
                  <Icons.GoogleColors />
                </SocialButton>
                <SocialButton title="login with Facebook account." onClick={loginViaFacebook}>
                  <Icons.FacebookRound />
                </SocialButton>
                <SocialErrorText isVisible={!!socialLoginErrorMessage}>
                  {socialLoginErrorMessage}
                </SocialErrorText>
              </SocialButtonsRow>

              <LoginForm onSubmit={onSubmit} />

              <Text>
                Wachtwoord vergeten?{' '}
                <Link passHref href={APP_ROUTES.FORGOT_PASSWORD}>
                  <a>Klik hier</a>
                </Link>
              </Text>

              <Text>
                Heb je nog geen account?{' '}
                <Link passHref href={APP_ROUTES.SIGN_UP}>
                  <a>Inschrijven</a>
                </Link>
              </Text>
            </Form>
          </StyledContentWrapper>
          {isMobile ? null : <SideImage />}
        </Content>
      </PageLayout>
    </>
  );
};

export default memo(Login);
