import styled from 'styled-components';
import { device } from '../../../../../constants/device';

export const CaptionText = styled.p`
  margin-bottom: 24px;
  font-style: normal;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 1.3;

  color: ${({ theme }) => theme.system.darkGreyActive};
`;

export const InputWrapper = styled.div`
  margin-bottom: 18px;
`;

export const SubmitButton = styled.button`
  margin: 24px 0;
  appearance: none;
  border: none;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  font-size: 1.5rem;
  line-height: 1.3;
  border-radius: 9px;

  color: ${({ theme }) => theme.system.white};
  background-color: ${({ theme }) => theme.brand.orange};

  &:hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.brand.darkOrange};
  }

  &[disabled] {
    background-color: ${({ theme }) => theme.system.disabled};
    cursor: not-allowed;
  }

  @media ${device.laptop} {
    margin-top: 95px;
  }
`;
