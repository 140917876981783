import styled from 'styled-components';
import { device } from '../../../constants/device';
import { ContentWrapper } from '../../common';

export const PageLayout = styled.div`
  width: 100%;
  min-height: 100vh;
  height: 100%;
  padding-top: 24px;
  background-color: ${({ theme }) => theme.brand.blue};
`;

export const StyledContentWrapper = styled(ContentWrapper)`
  display: flex;
  flex-direction: column;
`;

export const Content = styled.div`
  display: flex;
  width: 100%;
  min-height: calc(100vh - 24px);
  border-radius: 32px 0 0 0;
  background: ${({ theme }) => theme.system.white};

  @media ${device.laptop} {
    border-radius: 32px 32px 0 0;
  }
`;

export const SideImage = styled.div`
  flex: 0 0 50%;
  min-width: 0;
  background-image: url('/assets/sign-up/side-image.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
`;

export const LogoRow = styled.div`
  margin-top: 24px;
  margin-bottom: 30px;
  text-align: center;
`;

export const LogoLink = styled.a`
  display: inline-block;
  overflow: hidden;
`;

export const Form = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
`;

export const Title = styled.h1`
  font-weight: 700;
  font-size: 3.2rem;
  line-height: 1.3;
  margin-bottom: 38px;

  color: ${({ theme }) => theme.brand.blue};

  @media ${device.laptop} {
    font-weight: 700;
    font-size: 2rem;
    margin-bottom: 22px;

    br {
      display: none;
    }
  }
`;

export const SocialButton = styled.button`
  appearance: none;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
  width: 105px;
  height: 48px;
  border-radius: 8px;
  transition: ease-in border-color 0.3s;

  border: 1px solid ${({ theme }) => theme.brand.blue};
  background: ${({ theme }) => theme.system.white};

  &:hover {
    cursor: pointer;
    border-color: ${({ theme }) => theme.brand.orange};
  }
`;

export const SocialErrorText = styled.p<{ isVisible?: boolean }>`
  width: 100%;
  margin-top: 4px;
  height: 12px;
  visibility: ${({ isVisible }) => (isVisible ? 'visible' : 'hidden')};
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.2;
  color: ${({ theme }) => theme.system.red};
`;

export const SocialButtonsRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 40px;

  ${SocialButton} {
    margin-right: 16px;

    &:last-of-type {
      margin-right: 0;
    }
  }

  @media ${device.laptop} {
    justify-content: center;
    margin-bottom: 24px;
  }
`;

export const Text = styled.p`
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 1.3;
  margin-bottom: 24px;
  color: ${({ theme }) => theme.brand.blue};

  a {
    color: ${({ theme }) => theme.brand.orange};

    &:hover {
      color: ${({ theme }) => theme.brand.darkOrange};
    }
  }

  @media ${device.laptop} {
    text-align: center;
  }
`;
